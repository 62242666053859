const actions = {
SET_HARDWARE_CATEGORY: 'summary/SET_HARDWARE_CATEGORY',
  setHardwareCategory: (payload) => ({
    type: actions.SET_HARDWARE_CATEGORY,
    payload,
  }),

  SET_HARDWARE_DETAILS: 'summary/SET_HARDWARE_DETAILS',
  setHardwareDetails: (payload) => ({
    type: actions.SET_HARDWARE_DETAILS,
    payload,
  }),
  
  SET_DISTRO: 'summary/SET_DISTRO',
  setDistro: (payload) => ({
    type: actions.SET_DISTRO,
    payload,
  }),
  
  SET_MANAGEMENT: 'summary/SET_MANAGEMENT',
  setManagement: (payload) => ({
    type: actions.SET_MANAGEMENT,
    payload,
  }),
  
  SET_CONTROL_PANEL: 'summary/SET_CONTROL_PANEL',
  setControlPanel: (payload) => ({
    type: actions.SET_CONTROL_PANEL,
    payload,
  }),

  SET_CONTROL_PANEL_LICENSE: 'summary/SET_CONTROL_PANEL_LICENSE',
  setControlPanelLicense: (payload) => ({
    type: actions.SET_CONTROL_PANEL_LICENSE,
    payload,
  }),
  
  SET_BACKUPS: 'summary/SET_BACKUPS',
  setBackups: (payload) => ({
    type: actions.SET_BACKUPS,
    payload,
  }),
  
  SET_BLOCK_STORAGE: 'summary/SET_BLOCK_STORAGE',
  setBlockStorage: (payload) => ({
    type: actions.SET_BLOCK_STORAGE,
    payload,
  }),
  
  SET_IP_ADDRESSES: 'summary/SET_IP_ADDRESSES',
  setIpAddresses: (payload) => ({
    type: actions.SET_IP_ADDRESSES,
    payload,
  }),

  SET_IPV6: 'summary/SET_IPV6',
  setIPv6: (payload) => ({
    type: actions.SET_IPV6,
    payload,
  }),
  
  SET_DDOS: 'summary/SET_DDOS',
  setDdos: (payload) => ({
    type: actions.SET_DDOS,
    payload,
  }),
  
  SET_WINDOWS_LICENSE: 'summary/SET_WINDOWS_LICENSE',
  setWindowsLicense: (payload) => ({
    type: actions.SET_WINDOWS_LICENSE,
    payload,
  }),
  
  SET_MSSQL: 'summary/SET_MSSQL',
  setMsSql: (payload) => ({
    type: actions.SET_MSSQL,
    payload,
  }),

  SET_ANTI_VIRUS: 'summary/SET_ANTI_VIRUS',
  setAntiVirus: (payload) => ({
    type: actions.SET_ANTI_VIRUS,
    payload,
  }),

  RESET_SUMMARY: 'summary/RESET_SUMMARY',
  resetSummary: () => ({
    type: actions.RESET_SUMMARY,
  })
}

export default actions;
