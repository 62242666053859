// Our current hosting environment does not readily
// support x-frame-options or csp headers. For now,
// we'll hide the page and abort execution when framing
// is detected. This should be removed when the required
// security headers can be added.
//
// Framing is allowed in development so that the
// CMS preview function can work.
export const onClientEntry = (_, pluginOptions) => {
	const { environment } = pluginOptions

	if (environment === 'development') return

	if (window === window.top) return

	document.body.style.display = 'none'

	throw new Error('Framing not allowed')
}
