let isFirstRouteUpdate = true;

export const onRouteUpdate = ({ location }) => {
  if (!window._hsq) return

  if (isFirstRouteUpdate) {
    isFirstRouteUpdate = false;

    // The first page view is tracked automatically
    // when we import the tracking script.
    return;
  }


  window._hsq.push(['setPath', location.pathname])
  window._hsq.push(['trackPageView'])
}
