export const PRODUCT_VPS_LIN = 'Cloud.VPS'
export const PRODUCT_VPS_WIN = 'Cloud.VPS.WIN'
export const PRODUCT_METAL_LIN = 'Cloud.Metal'
export const PRODUCT_METAL_WIN = 'Cloud.Metal.WIN'

export const CLOUD_PRODUCTS = [
  PRODUCT_VPS_LIN,
  PRODUCT_VPS_WIN,
  PRODUCT_METAL_LIN,
  PRODUCT_METAL_WIN,
]

export const CART_REDIRECT_URL = 'https://my.liquidweb.com/cart/'
export const CART_DETAILS_URL = 'https://api.liquidweb.com/cloud/server/detailsForCart'
export const ADD_TO_CART_URL = 'https://api.liquidweb.com/cloud/server/addToCart'
export const BASKET_DETAILS_URL = 'https://api.liquidweb.com/basket/details'

export const BASIC_AUTH = {
	username: 'remote-signup',
	password: 's|gnm3up',
}

export const ROOT_MARGIN = '-25% 0% -75% 0%'
export const SCROLL_OFFSET = 180
export const CONFIGURATION_SECTIONS = [
  {
    id: 'location',
    label: 'Location'
  },
  {
    id: 'os',
    label: 'Operating System'
  },
  {
    id: 'management',
    label: 'Management'
  },
  {
    id: 'template-controls',
    label: 'Template & Controls'
  },
  {
    id: 'add-ons',
    label: 'Add-Ons'
  },
]

export const VPS_MAP = new Map([
  ['cores', {label: 'Cores', suffix: ''}],
  ['memory', {label: 'Memory', suffix: 'GB'}],
  ['disk', {label: 'Disk', suffix: 'GB'}],
  ['bandwidth', {label: 'Bandwidth', suffix: 'GB'}],
])

export const METAL_MAP = new Map([
  ['cores', {label: 'Cores', suffix: ''}],
  ['cpu_speed', {label: 'Speed', suffix: 'MHz'}],
  ['memory', {label: 'Memory', suffix: 'GB'}],
  ['disk', {label: 'Disk', suffix: 'GB'}],
  ['bandwidth', {label: 'Bandwidth', suffix: 'GB'}],
])

export const SUMMARY_HARDWARE_VPS = new Map([
  ['cores', {label: 'Cores', suffix: ''}],
  ['memory', {label: 'Memory', suffix: 'GB'}],
  ['disk', {label: 'Disk', suffix: 'GB'}],
  ['bandwidth', {label: 'Bandwidth', suffix: 'GB'}],
])

export const SUMMARY_HARDWARE_VPS_WIN = new Map([
  ['cores', {label: 'Cores', suffix: ''}],
  ['memory', {label: 'Memory', suffix: 'GB'}],
  ['disk', {label: 'Disk', suffix: 'GB'}],
  ['bandwidth', {label: 'Bandwidth', suffix: 'GB'}],
])

export const SUMMARY_HARDWARE_METAL = new Map([
  ['cores', {label: 'Cores', suffix: ''}],
  ['cpu_speed', {label: 'Speed', suffix: 'MHz'}],
  ['memory', {label: 'Memory', suffix: 'GB'}],
  ['disk', {label: 'Disk', suffix: 'GB'}],
  ['bandwidth', {label: 'Bandwidth', suffix: 'GB'}],
])

export const SUMMARY_HARDWARE_METAL_WIN = new Map([
  ['cores', {label: 'Cores', suffix: ''}],
  ['cpu_speed', {label: 'Speed', suffix: 'MHz'}],
  ['memory', {label: 'Memory', suffix: 'GB'}],
  ['disk', {label: 'Disk', suffix: 'GB'}],
  ['bandwidth', {label: 'Bandwidth', suffix: 'GB'}],
])

export const SUMMARY_ADDONS = ['backups','blockStorage','ipAddresses','ipv6','ddos']

export const SUMMARY_ADDONS_WIN = ['antiVirus','mssql']

export const LOCATION_MAP = new Map([
  ['lan', {
    city: 'Michigan',
    region: 'US-Central',
    country: 'United States',
    countryCode: 'usa',
  }],
  ['phx', {
    city: 'Arizona',
    region: 'US-West',
    country: 'United States',
    countryCode: 'usa',
  }],
  ['ams', {
    city: 'Amsterdam',
    region: 'EU-West',
    country: 'Netherlands',
    countryCode: 'nl',
  }]
])

export const HARDWARE_CATEGORY_ORDER = ['gp', 'mem-opt', 'cpu-opt']

export const ERROR_MANAGEMENT_LEVEL = 'Please choose another location to see more options.'
export const ERROR_API_FETCH = 'There was a problem loading our product data. Please refresh and try again'
export const ERROR_API_POST = 'There was a problem adding deploying your configuration. Please contact our support.'

export const COMPARE_MANAGEMENT_LEVELS = [
  ['24/7/365', 1, 1, 1],
  ['Fully Managed Network Infrastructure', 1, 1, 1],
  ['Fully Managed Hardware', 1, 1, 1],
  ['Wholly owned Liquid Web Data Centers', 1, 1, 1],
  ['Level 3 Technicians On-site 24/7/365', 1, 1, 1],
  ['System Level Health Monitoring and Graphing', 1, 1, 1],
  ['System Level Monitoring Alerts & Notifications', 1, 1, 1],
  ['100% Uptime SLA on These Items', 1, 1, 1],
  ['Dedicated Account Executive Team', 1, 1, 1],
  ['Installation and Full Support of Core Software Package', 0, 1, 1],
  ['Core Operating System Updates & Patches', 0, 1, 1],
  ['Security Enhancements', 0, 1, 1],
  ['Full Web Server Support including Apache (Linux)', 0, 1, 1],
  ['Proactive Response & Restoration of Monitoring Events', 0, 1, 1],
  ['Virus and Spam Protection', 0, 0, 1],
  ['Free External Migrations**', 0, 0, 1],
  ['Full Control Panel Support', 0, 0, 1],
  ['Control Panel Updates and Patches', 0, 0, 1],
]

export const HARDWARE_CATEGORY_DESCRIPTIONS = new Map([
  ['gp', 'Virtual machines with balanced resources suitable for a variety of workloads. Useful for website hosting, development/testing, and general application hosting.'],
  ['mem-opt', 'Virtual machines focused on providing larger amounts of memory. Useful for databases, in-memory caches, and other memory bound workloads.'],
  ['cpu-opt', 'Virtual machines focused on providing more processing cores. Useful for CI/CD, video processing, and other cpu bound workloads.'],
])
