import React from 'react'
import { sendEvent as sendAnalyticsEvent } from '../../utils/analytics'
import { useChat } from './ChatProvider'

const CHAT_ELEMENT_SELECTOR =
  '.chat-action, .live-chat, .live-chat-action'

const GlobalChatClickHandler: React.FC = ({ children }) => {
  const chat = useChat()

  const onClick = event => {
    const { target } = event

    if (!target.matches(CHAT_ELEMENT_SELECTOR)) return

    event.preventDefault()

    sendAnalyticsEvent({
      category: 'chat',
      action: 'cta_clicked',
      label: 'content',
    })

    target.classList.add('is-starting')
    chat.startChat().finally(() => {
      target.classList.remove('is-starting')
    })
  }

  return <div onClick={onClick}>{children}</div>
}

export default GlobalChatClickHandler
