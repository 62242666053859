import React, {
  createContext,
  useContext,
  useRef,
  useCallback,
  useReducer,
} from 'react'

export type CommonStateValueT = string | undefined
export type CommonStateValueContextT = Record<string, CommonStateValueT>
export type CommonStateActionT = [string, CommonStateValueT]
export type CommonStateContextT = [
  CommonStateValueContextT,
  React.Dispatch<CommonStateActionT>
]

const context = createContext<CommonStateContextT>(null!)

function commonStateReducer(
  state: CommonStateValueContextT,
  action: CommonStateActionT
): CommonStateValueContextT {
  const [key, value] = action
  if (state[key] === value) return state
  return { ...state, [key]: value }
}

export function useCommonStateContext(): CommonStateContextT {
  return useContext(context)
}

export type UseCommonStateStateReturnT<T = CommonStateValueT> = [
  T,
  React.Dispatch<React.SetStateAction<T>>
]

export default function useCommonStateState(
  key: string
): UseCommonStateStateReturnT {
  const [contextState, dispatch] = useCommonStateContext()

  const state = contextState[key]
  const stateRef = useRef<CommonStateValueT>()
  stateRef.current = state

  const setState: React.Dispatch<React.SetStateAction<CommonStateValueT>> =
    useCallback(
      s => {
        // Copy react setState behavior
        const res = s instanceof Function ? s(stateRef.current) : s
        dispatch([key, res])
      },
      [key, dispatch]
    )

  return [state, setState]
}

export function useCommonStateBoolean(
  key: string
): UseCommonStateStateReturnT<boolean> {
  const [state, setState] = useCommonStateState(key)

  const setBoolean: React.Dispatch<React.SetStateAction<boolean>> = useCallback(
    s => {
      if (s instanceof Function) {
        setState(current => {
          return s(current === '1') ? '1' : '0'
        })
      } else {
        setState(s ? '1' : '0')
      }
    },
    [setState]
  )

  return [state === '1', setBoolean]
}

export interface CommonStateProviderPropsI {
  children: React.ReactNode;
}

export function CommonStateProvider(
  props: CommonStateProviderPropsI
): JSX.Element {
  const { children } = props

  const value = useReducer(commonStateReducer, {} as CommonStateValueContextT)

  return <context.Provider value={value}>{children}</context.Provider>
}
