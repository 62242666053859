import React, { useContext } from 'react'
import { ChatProvider } from '../chat/ChatProvider'

const PageContext = React.createContext()

const PageWrapper: React.FC = ({ children, ...otherProps }) => {
  return (
    <PageContext.Provider value={otherProps}>
      <ChatProvider>
        {children}
      </ChatProvider>
    </PageContext.Provider>
  )
}

export const usePage: Record<string, unknown> = () =>
  useContext(PageContext)

export default PageWrapper
