import React from 'react'
import { AppConfigProvider } from '../../config'
import { AppStateProvider } from '../../state'
import { SearchFormProvider } from '../search/searchFormContext'
import { CommonStateProvider } from '../commonStateContext'

import PII from './PII'

const RootWrapper: React.FC = ({ children }) => (
  <AppConfigProvider>
    {/* The following three should be combined */}
    <CommonStateProvider>
      <AppStateProvider>
        <SearchFormProvider>
          <PII />
          {children}
        </SearchFormProvider>
      </AppStateProvider>
    </CommonStateProvider>
  </AppConfigProvider>
)

export default RootWrapper
