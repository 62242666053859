import actions from './actions'

const initialState = {
  isOpen: false,
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case actions.SET_IS_OPEN:
      return {
        ...state,
        isOpen: payload,
      };

    default:
      return state;
  }
};

export default reducer;
