import React, { createContext, useContext, useMemo, useReducer } from 'react'

import authReducer from './auth/reducer'
import chatReducer from './chat/reducer'
import piiReducer from './pii/reducer'
import configurationReducer from './configuration/reducer'
import summaryReducer from './summary/reducer'

const sliceReducers = {
  auth: authReducer,
  chat: chatReducer,
  pii: piiReducer,
  configuration: configurationReducer,
  summary: summaryReducer,
}

const initialState = Object.keys(sliceReducers).reduce(
  (acc, slice) => ({
    ...acc,
    // Invoke each reducer with no state and
    // a void action to get its initial state
    [slice]: sliceReducers[slice](undefined, {}),
  }),
  {}
)

const rootReducer = (state, action) =>
  Object.keys(sliceReducers).reduce(
    (acc, slice) => ({
      ...acc,
      [slice]: sliceReducers[slice](acc[slice], action),
    }),
    state
  )

export const AppStateContext = createContext()

export const AppStateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(rootReducer, initialState)
  const store = useMemo(() => [state, dispatch], [state])

  return (
    <AppStateContext.Provider value={store}>
      {children}
    </AppStateContext.Provider>
  )
}

export const useAppState = () => useContext(AppStateContext)
