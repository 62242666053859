import { getCLS, getFID, getLCP, getTTFB, getFCP } from 'web-vitals'

window.dataLayer = window.dataLayer || [];

const sendToGoogleTagManager = ({ name, value, delta, id }) => {
  window.dataLayer.push({
    event: 'lw.webVitals',
    webVitals: {
      name,
      id,
      delta,
      value,
      deltaRounded: Math.round(name === 'CLS' ? delta * 1000 : delta),
      valueRounded: Math.round(name === 'CLS' ? value * 1000 : value),
    },
  })
}

export const reportWebVitals = () => {
  try {
    getCLS(sendToGoogleTagManager)
    getFID(sendToGoogleTagManager)
    getLCP(sendToGoogleTagManager)
    getTTFB(sendToGoogleTagManager)
    getFCP(sendToGoogleTagManager)
  } catch (err) {
    // Do nothing
  }
};
