// Reference: https://docs.wpgraphql.com/getting-started/menus/#converting-a-flat-list-to-hierarchical-list
export const flatListToHierarchical = (
  data = [],
  { idKey = 'key', parentKey = 'parentId', childrenKey = 'children' } = {}
) => {
  const tree = []
  const childrenOf = {}

  data.forEach(item => {
    const newItem = { ...item }
    const { [idKey]: id, [parentKey]: parentId = 0 } = newItem
    childrenOf[id] = childrenOf[id] || []
    newItem[childrenKey] = childrenOf[id]
    parentId
      ? (childrenOf[parentId] = childrenOf[parentId] || []).push(newItem)
      : tree.push(newItem)
  })

  return tree
}

export const once = function (fn) {
  let originalFunction = fn
  let result

  return function () {
    if (!originalFunction) return result

    result = originalFunction.apply(this, arguments)
    originalFunction = null

    return result
  }
}
