import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { DialogOverlay, DialogContent } from '@reach/dialog'
import { useChat } from './ChatProvider'

const SDialogOverlay = styled(DialogOverlay)`
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.5);
`

const SDialogContent = styled(DialogContent)`
  position: relative;

  @media (max-width: 767px) {
    width: 90vw;
  }
`

const SCloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 16px;

  :focus {
    outline: none;
  }
`

const TimeoutDialog: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false)
  const { chatModule } = useChat()

  useEffect(() => {
    if (!chatModule) return

    const handleTimeout = () => setIsOpen(true)

    chatModule.emitter.on('LWButtonClickTimeout', handleTimeout)
    return () => chatModule.emitter.off('LWButtonClickTimeout', handleTimeout)
  }, [chatModule])

  if (!isOpen) return null

  const handleDismiss = () => setIsOpen(false)

  return (
    <SDialogOverlay onDismiss={handleDismiss}>
      <SDialogContent>
        <SCloseButton
          onClick={handleDismiss}
          aria-label="close"
        >
          x
        </SCloseButton>
        <div className="container">
          <h2 className="mb-4">Hmm ... </h2>
          <p className="mb-4">
            It looks like chat failed to initialize.
          </p>
          <p>
            Our chat service requires third-party cookies and browser
            session storage to be enabled in order for chat to operate.
            Please ensure your browser is allowing third-party storage
            for this session, and try again.
          </p>
        </div>
        <div
          className="text-center mt-12 font-light"
          style={{ color: '#808080' }}
        >
          <div>Need help? Call us any time.</div>
          <div><a href="tel:18005804985">800.580.4985</a> or <a href="tel:+15173220434">1.517.322.0434</a></div>
        </div>

      </SDialogContent>
    </SDialogOverlay>
  )
}

export default TimeoutDialog
