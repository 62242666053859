import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useAppConfig } from '../../config'
import { useAppState } from '../../state'
import authSelectors from '../../state/auth/selectors'
import chatActions from '../../state/chat/actions'
import { usePage } from '../util/PageWrapper'
import { sendEvent as sendAnalyticsEvent } from '../../utils/analytics'
import { once } from '../../utils'
import GlobalChatClickHandler from './GlobalChatClickHandler'
import TimeoutDialog from './TimeoutDialog'

import { getLastChatTime } from './util'

const IMMEDIATE_INIT_RECENCY_THRESHOLD = 3600000; // 1 hour

const isSSR = typeof window === 'undefined'

const reportInitDelayOnce = once((initDelay) => {
  sendAnalyticsEvent({
    category: 'chat',
    action: 'initDelay_measured',
    value: initDelay,
    isNonInteraction: true,
  })
})

const getChatDepartment = (data) => {
  if (!data) return null;

  const { wpPage, page, post } = data;

  if (wpPage) return wpPage?.chatDepartment;
  if (page) return page?.chatDepartment;
  if (post) return post?.chatDepartment;

  return null;
}

const ChatContext = React.createContext()

export const ChatProvider: React.FC = ({ children }) => {
  const [chatModule, setChatModule] = useState(null)
  const [state, dispatch] = useAppState();
  const page = usePage()
  const appConfig = useAppConfig()

  // Import lwChat in browsers only
  useEffect(() => {
    if (isSSR) return
    import('../../lib/lw-chat').then(module => setChatModule(module))
  }, [])

  // Watch for events indicating the chat is either open
  // or closed, and update app state accordingly.
  useEffect(() => {
    if (!chatModule) return;

    const emitter = chatModule.emitter;

    const handleMaximizeOrMinimize = () => dispatch(chatActions.setIsOpen(true));
    const handleDestroy = () => dispatch(chatActions.setIsOpen(false));

    emitter.on('afterMaximize', handleMaximizeOrMinimize);
    emitter.on('afterMinimize', handleMaximizeOrMinimize);
    emitter.on('afterDestroy', handleDestroy);

    return () => {
      emitter.off('afterMaximize', handleMaximizeOrMinimize);
      emitter.off('afterMinimize', handleMaximizeOrMinimize);
      emitter.off('afterDestroy', handleDestroy);
    }
  }, [chatModule, dispatch])

  const init = useCallback(() => {
    if (!chatModule) return

    const { chat: { lwChat: lwChatConfig } } = appConfig
    return chatModule.init(lwChatConfig)
  }, [chatModule, appConfig])

  useEffect(() => {
    if (!chatModule) return

    const { chat: { autoInitDelay } } = appConfig

    const lastStartTime = getLastChatTime() || 0;
    const timeSinceLastChat = new Date().getTime() - lastStartTime;

    if (timeSinceLastChat < IMMEDIATE_INIT_RECENCY_THRESHOLD) {
      // If chat has been active recently, initialize
      // immediately to ensure any ongoing session is
      // promptly resumed.
      return init()
    }

    // Otherwise, defer initialization for a moment in
    // order to keep chat out of the critical rendering
    // path.
    const id = setTimeout(init, autoInitDelay)
    return () => clearTimeout(id)
  }, [appConfig, chatModule, init])

  const getChatAuthToken = useCallback(async () => {
    const accessToken = authSelectors.getAccessToken(state);

    if (!accessToken) return null;

    const { hosts: { apiPublic: apiPublicHostname } } = appConfig

    const res = await fetch(`https://${apiPublicHostname}/salesforce/chat/auth/issuejwt.json`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'X-Force-Compliant-HTTP-Status-Codes': 1,
      },
    })

    if (!res.ok) return null;

    const { jwt } = await res.json();

    return jwt;
  }, [appConfig, state])

  const startChat = useCallback(async (params = {}) => {
    if (!chatModule) return

    const startTime = new Date().getTime();

    await init()

    const preselectDepartment = getChatDepartment(page.data)
    const { chat: { defaultDepartment } } = appConfig

    const startChatParams = {
      ...params,
      extraDetails: {
        isInvitation: false,
        preselectDepartment: preselectDepartment || defaultDepartment,
        googlePageUrl: window.location.href,
        ChatAuthToken: await getChatAuthToken() || '',
        ...params.extraDetails,
      },
    };

    return chatModule
      .startChat(startChatParams)
      .then((result) => {
        const initDelay = new Date().getTime() - startTime;

        reportInitDelayOnce(initDelay);

        return result;
      })

  }, [appConfig, chatModule, getChatAuthToken, init, page.data])

  const contextValue = useMemo(() => ({
    chatModule,
    preselectDepartment: getChatDepartment(page.data),
    startChat,
  }), [chatModule, page.data, startChat])

  return (
    <ChatContext.Provider value={contextValue}>
      <GlobalChatClickHandler>
        <TimeoutDialog />
        {children}
      </GlobalChatClickHandler>
    </ChatContext.Provider>
  )
}

export const useChat = () => useContext(ChatContext)
