import Cookies from 'js-cookie'

const LAST_CHAT_TIME_KEY = 'lwChatLastStartTime';
const INVITE_DISMISSED_UNTIL_KEY = 'lwChatInviteDismissedUntil';

const getCookieDomain = () => {
	const isLW = /\.liquidweb\.com$/.test(window.location.hostname);
	return isLW ? 'liquidweb.com' : window.location.hostname;
};

const setCookie = (name, value, options = {}) => {
	const cookieOptions = {
		path: '/',
		domain: getCookieDomain(),
		...options,
	}

	Cookies.set(name, value, cookieOptions)
}

export const getLastChatTime = () => Cookies.get(LAST_CHAT_TIME_KEY)

export const getInviteDismissedUntil = () => Cookies.get(INVITE_DISMISSED_UNTIL_KEY)
export const setInviteDismissedUntil = (time) => setCookie(INVITE_DISMISSED_UNTIL_KEY, time)
