// Custom fonts
import 'typeface-open-sans'
import './src/css/icons-54.css'
import './src/css/style.css'

import { reportWebVitals } from './src/utils/web-vitals'
import { wrapPageElement } from './src/wrap-page-element'
import { wrapRootElement } from './src/wrap-root-element'

// Anchor Tags
export const shouldUpdateScroll = ({ routerProps: { location } }) => {
  if (location.hash) {
    setTimeout(() => {
      document.querySelector(`${location.hash}`).scrollIntoView()
    }, 0)
  }
}

export const onClientEntry = () => {
	reportWebVitals();
}

export {
  wrapPageElement,
  wrapRootElement
}
