import actions from './actions'
import { PRODUCT_VPS_LIN } from '../../components/configuration/constants';

const initialState = {
  resetToggle: true,
  isLoading: true,
  isError: '', // api-fetch|api-post|cart|management
  errorMessage: 'An error has occurred',
  productCode: PRODUCT_VPS_LIN,
  serverType: 'vps',
  serverLocation: 'lan',
  serverZoneId: '27',
  serverRegionId: '1',
  operatingSystemId: '', // Composed value of managementLevel, operatingSystemType and operatingSystemVersion
  operatingSystemType: 'linux', // windows|linux
  operatingSystemDistro: '', // Windows|CentOS|Ubuntu|AlmaLinux|Debian|Rocky Linux|Fedora
  operatingSystemVersion: '',
  managementLevel: 'Self-Managed',
  controlPanel: '',
  templateValue: '',
  hardwareTab: 'gp',
  hardwareOption: '',
  backupsEnabled: false,
  blockStorage: 0,
  ipAddresses: 1,
  addIPv6: false,
  ddosOption: 'Standard', // Standard|Advanced
  cPanelLicenseTier: '',
  windowsLicense: '',
  windowsMsSQL: 'None',
  windowsAntiVirus: 'None',
  avaiableAntiVirus: [],
  availableMsSQL: [],
  serverQty: 1,
  configurationData: {}, // Main data object for product offerents (VPS|VPS.WINDOWS|METAL|METAL.WINDOWS)
  managementLevelData: [],
  availableDistros: {},
  showImplicitConfigUpdateNotice: false,
}

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case actions.SET_IS_LOADING:
      return {
        ...state,
        isLoading: payload,
      }
    case actions.SET_IS_ERROR:
      return {
        ...state,
        isError: payload,
      }
    case actions.SET_ERROR_MESSAGE:
      return {
        ...state,
        errorMessage: payload,
      }
    case actions.SET_SERVER_TYPE:
      return {
        ...state,
        serverType: payload,
      }
    case actions.SET_SERVER_LOCATION:
      return {
        ...state,
        serverLocation: payload,
      }
    case actions.SET_SERVER_ZONE_ID:
      return {
        ...state,
        serverZoneId: payload,
      }
    case actions.SET_SERVER_REGION_ID:
      return {
        ...state,
        serverRegionId: payload,
      }
    case actions.SET_HARDWARE_TAB:
      return {
        ...state,
        hardwareTab: payload,
      }
    case actions.SET_HARDWARE_OPTION:
      return {
        ...state,
        hardwareOption: payload,
      }
    case actions.SET_OPERATING_SYSTEM_ID:
      return {
        ...state,
        operatingSystemId: payload,
      }
    case actions.SET_OPERATING_SYSTEM_TYPE:
      return {
        ...state,
        operatingSystemType: payload,
      }
    case actions.SET_OPERATING_SYSTEM_DISTRO:
      return {
        ...state,
        operatingSystemDistro: payload,
      }
    case actions.SET_OPERATING_SYSTEM_VERSION:
      return {
        ...state,
        operatingSystemVersion: payload
      }
    case actions.SET_CONTROL_PANEL:
      return {
        ...state,
        controlPanel: payload,
      }
    case actions.SET_TEMPLATE_VALUE:
      return {
        ...state,
        templateValue: payload,
      }
    case actions.SET_MANAGEMENT_LEVEL:
      return {
        ...state,
        managementLevel: payload,
      }
    case actions.SET_BACKUPS:
      return {
        ...state,
        backupsEnabled: !state.backupsEnabled,
      }
    case actions.SET_BLOCK_STORAGE:
      return {
        ...state,
        blockStorage: payload,
      }
    case actions.SET_IP_ADDRESSES:
      return {
        ...state,
        ipAddresses: payload,
      }
    case actions.SET_IPV6:
      return {
        ...state,
        addIPv6: !state.addIPv6,
      }
    case actions.SET_SERVER_QTY:
      return {
        ...state,
        serverQty: payload,
      }
    case actions.SET_DDOS_OPTION:
      return {
        ...state,
        ddosOption: payload,
      }
    case actions.SET_CPANEL_LICENSE_TIER:
      return {
        ...state,
        cPanelLicenseTier: payload,
      }
    case actions.RESET_CONFIGURATION:
      return {
        ...initialState,
        resetToggle: !state.resetToggle,
        isLoading: false,
      }
    case actions.SET_CONFIGURATION_DATA:
      return {
        ...state,
        configurationData: payload,
      }
    case actions.SET_MANAGEMENT_LEVEL_DATA:
      return {
        ...state,
        managementLevelData: payload
      }
    case actions.SET_PRODUCT_CODE:
      return {
        ...state,
        productCode: payload,
      }
    case actions.SET_AVAILABLE_DISTROS:
      return {
        ...state,
        availableDistros: payload,
      }
    case actions.SET_WINDOWS_LICENSE:
      return {
        ...state,
        windowsLicense: payload,
      }
    case actions.SET_WINDOWS_MSSQL:
      return {
        ...state,
        windowsMsSQL: payload,
      }
    case actions.SET_WINDOWS_ANTI_VIRUS:
      return {
        ...state,
        windowsAntiVirus: payload,
      }
    case actions.SET_AVAILABLE_MSSQL:
      return {
        ...state,
        availableMsSQL: payload,
      }
    case actions.SET_AVAILABLE_ANTI_VIRUS:
      return {
        ...state,
        availableAntiVirus: payload,
      }
    case actions.TOGGLE_IMPLICIT_CONFIG_UPDATE_NOTICE:
      return {
        ...state,
        showImplicitConfigUpdateNotice: payload ?? !state.showImplicitConfigUpdateNotice,
      }
    default:
      return state;
  }
}

export default reducer;
