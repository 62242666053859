import memoize from 'lodash/memoize'

const getIsLoggedIn = memoize(state => {
  const {
    auth: { userData },
  } = state

  if (!userData) return false

  const { access_token: accessToken, expires_at: expiresAt } = userData

  if (!accessToken) return false

  return expiresAt * 1000 > new Date().getTime()
})

const getAccessToken = memoize(state => {
	const { auth: { userData } } = state;

	if (!userData) return null;

	const { access_token: accessToken } = userData;

	if (!accessToken) return null;

	return accessToken;
});

const getUsername = memoize(state => {
	const accessToken = getAccessToken(state);

	if (!accessToken) return null

	try {
		const [, encodedPayload] = accessToken.split('.');
		const payloadJSON = atob(encodedPayload);
		const { username } = JSON.parse(payloadJSON);

		return username;
	} catch (err) {
		console.error(err);
		return null;
	}
});


const selectors = {
	getAccessToken,
	getIsLoggedIn,
	getUsername,
}

export default selectors;
