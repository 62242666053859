import actions from './actions'

const initialState = {
  isAccountModalOpen: false,
  isEnabled: false,
  isImmediate: false,
  userData: null,
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case actions.ENABLE:
      return {
        ...state,
        isEnabled: true,
      };

    case actions.ENABLE_IMMEDIATE:
      return {
        ...state,
        isEnabled: true,
        isImmediate: true,
      };

    case actions.SET_IS_IMMEDIATE:
      return {
        ...state,
        isImmediate: payload,
      };

    case actions.SET_USER_DATA:
      return {
        ...state,
        userData: payload,
      };

    case actions.SET_IS_ACCOUNT_MODAL_OPEN:
      return {
        ...state,
        isAccountModalOpen: payload,
      };

    default:
      return state;
  }
};

export default reducer;
