const actions = {
  SET_DETAILS: 'pii/SET_DETAILS',
  setDetails: (payload) => ({
    type: actions.SET_DETAILS,
    payload,
  }),

  SET_ERROR: 'pii/SET_ERROR',
  setError: () => ({
    type: actions.SET_ERROR,
  }),

};

export default actions;
