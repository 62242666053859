import React, { useCallback, useEffect } from 'react'
import Cookies from 'js-cookie'

import { useAppState } from '../../state'
import piiActions from '../../state/pii/actions'

const PII_COOKIE_NAME = 'lwPiiPreferences'
const PII_PIXEL_URL = 'https://cart.liquidweb.com/ajax/dataconsentcookies'

const PII: React.FC = () => {
  const [, dispatch] = useAppState()

  const getCookieDataAndUpdateState = useCallback(() => {
    const piiCookieValue = Cookies.get(PII_COOKIE_NAME)

    if (!piiCookieValue) return undefined

    const piiData = JSON.parse(piiCookieValue)
    dispatch(piiActions.setDetails(piiData))

    return piiData
  }, [dispatch])

  const loadPixelAndUpdateState = useCallback(() => {
    const pixel = new Image()

    pixel.onload = () => {
      const cookieData = getCookieDataAndUpdateState()
      if (!cookieData) {
        dispatch(piiActions.setError())
      }
    }

    pixel.onerror = () => dispatch(piiActions.setError())

    pixel.src = PII_PIXEL_URL
  }, [dispatch, getCookieDataAndUpdateState])

  useEffect(() => {
    const cookieData = getCookieDataAndUpdateState()
    if (!cookieData) loadPixelAndUpdateState()
  }, [getCookieDataAndUpdateState, loadPixelAndUpdateState])

  return null
}

export default PII
