import React, { createContext, useContext, useState } from 'react'

const searchFormContext = createContext()

export default function useSearchFormContext() {
  return useContext(searchFormContext)
}

export function SearchFormProvider({ children }) {
  const [isSearchVisible, setSearchVisibility] = useState(false)

  return (
    <searchFormContext.Provider value={[isSearchVisible, setSearchVisibility]}>
      {children}
    </searchFormContext.Provider>
  )
}
