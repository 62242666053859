import actions from './actions'

const initialState = {
  hardwareCategory: {
    title: '',
    value: '',
    cost: 0,
  },
  hardwareDetails: {},
  distro: {
    title: 'Operating System',
    value: '',
    cost: 0,
  },
  management: {
    title: 'Management',
    value: '',
    cost: 0,
  }, 
  controlPanel: {
    title: 'Control Panel',
    value: '',
    cost: 0,
  },
  controlPanelLicense: {
    title: 'Control Panel License',
    value: '',
    cost: 0,
  },
  backups: {
    title: 'Cloud Backups',
    value: 'No',
    cost: 0,
  },
  blockStorage: {
    title: 'Cloud Block Storage',
    value: 'None',
    cost: 0,
  },
  ipAddresses: {
    title: 'IP Addresses',
    value: '1',
    cost: 0,
  },
  ipv6: {
    title: 'IPv6',
    value: 'No',
    cost: 0,
  },
  ddos: {
    title: 'DDoS Protection',
    value: 'Standard',
    cost: 0,
  },
  license: {
    title: 'License',
    value: '',
    cost: 0,
  },
  mssql: {
    title: 'MsSQL Server',
    value: 'None',
    cost: 0,
  },
  antiVirus: {
    title: 'Anti-Virus',
    value: 'None',
    cost: 0,
  }
}

const reducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case actions.SET_HARDWARE_CATEGORY:
      return {
        ...state,
        hardwareCategory: payload
      }
    case actions.SET_HARDWARE_DETAILS:
      return {
        ...state,
        hardwareDetails: payload
      }
    case actions.SET_DISTRO:
      return {
        ...state,
        distro: Object.assign({},
          state.distro,
          payload
        )
      }
    case actions.SET_MANAGEMENT:
      return {
        ...state,
        management: Object.assign({},
          state.management,
          payload
        ),
      }
    case actions.SET_CONTROL_PANEL:
      return {
        ...state,
        controlPanel: Object.assign({},
          state.controlPanel,
          payload
        ),
      }
    case actions.SET_CONTROL_PANEL_LICENSE:
      return {
        ...state,
        controlPanelLicense: Object.assign({},
          state.controlPanelLicense,
          payload
        )
      }
    case actions.SET_BACKUPS:
      return {
        ...state,
        backups: Object.assign({},
          state.backups,
          payload
        )
      }
    case actions.SET_BLOCK_STORAGE:
      return {
        ...state,
        blockStorage: Object.assign({},
          state.blockStorage,
          payload
        )
      }
    case actions.SET_IP_ADDRESSES:
      return {
        ...state,
        ipAddresses: Object.assign({},
          state.ipAddresses,
          payload
        )
      }
    case actions.SET_IPV6:
      return {
        ...state,
        ipv6: Object.assign({},
          state.ipv6,
          payload
        )
      }
    case actions.SET_DDOS:
      return {
        ...state,
        ddos: Object.assign({},
          state.ddos,
          payload
        )
      }
    case actions.SET_WINDOWS_LICENSE:
      return {
        ...state,
        license: Object.assign({},
          state.license,
          payload
        )
      }
    case actions.SET_MSSQL:
      return {
        ...state,
        mssql: Object.assign({},
          state.mssql,
          payload
        )
      }
    case actions.SET_ANTI_VIRUS:
      return {
        ...state,
        antiVirus: Object.assign({},
          state.antiVirus,
          payload
        )
      }
    case actions.RESET_SUMMARY:
      return {
        ...initialState
      }
    default:
      return state
  }
}

export default reducer;
