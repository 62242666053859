const actions = {
  ENABLE: 'auth/ENABLE',
  enable: () => ({ type: actions.ENABLE }),

  ENABLE_IMMEDIATE: 'auth/ENABLE_IMMEDIATE',
  enableImmediate: () => ({ type: actions.ENABLE_IMMEDIATE }),

  SET_IS_IMMEDIATE: 'auth/SET_IS_IMMEDIATE',
  setIsImmediate: (payload) => ({
    type: actions.SET_IS_IMMEDIATE,
    payload,
  }),

  SET_USER_DATA: 'auth/SET_USER_DATA',
  setUserData: (payload) => ({
    type: actions.SET_USER_DATA,
    payload,
  }),

  SET_IS_ACCOUNT_MODAL_OPEN: 'auth/SET_IS_ACCOUNT_MODAL_OPEN',
  setIsAccountModalOpen: (payload) => ({
    type: actions.SET_IS_ACCOUNT_MODAL_OPEN,
    payload,
  })

};

export default actions;
