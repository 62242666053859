const isSSR = typeof window === 'undefined'

export const sendEvent = ({
  category,
  action,
  label,
  value,
  isNonInteraction = false,
}) => {
  if (isSSR) return

  if (!category || !action) throw new Error('Category and action are required')

  if (!/^[A-Z0-9]+?_[A-Z0-9]+$/i.test(action)) {
    // e.g. invitation_dismissed
    throw new Error('Action must follow object_action form')
  }

  window.dataLayer = window.dataLayer || []

  const data = {
    category,
    action: `lw:${action}`,
    label,
    value,
    isNonInteraction,
  }

  window.dataLayer.push({
    event: 'lw.structured',
    data,
  })
}
