import actions from './actions'

const initialState = {
  details: null,
  hasError: false,
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case actions.SET_DETAILS:
      return {
        ...state,
        details: payload,
        hasError: false,
      };

    case actions.SET_ERROR:
      return {
        ...state,
        details: null,
        hasError: true,
      };

    default:
      return state;
  }
};

export default reducer;
