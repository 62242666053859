import React, { useContext, useMemo } from 'react'
import { useStaticQuery, graphql } from 'gatsby'

const AppConfigContext = React.createContext()

export const AppConfigProvider = ({ children }) => {
	const { site } = useStaticQuery(graphql`
		query {
		  site {
		  	lwAppConfig
		  }
		}		
	`);

	const contextValue = useMemo(
		() => JSON.parse(site.lwAppConfig),
		[site.lwAppConfig],
	)

	return (
		<AppConfigContext.Provider value={contextValue}>
			{children}
		</AppConfigContext.Provider>
	);
}

export const useAppConfig = () => useContext(AppConfigContext);
